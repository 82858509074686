<template>
  <div>
    <Letters
      v-if="$router.currentRoute.path == '/' + role + '/secretariat/inbox'"
      :letters="letters"
      :notSeen="notSeen"
      :type="'inbox'"
      :isLoaded="isLoaded"
      @save="save"
      @removeSave="removeSave"
      @archive="archive"
      @removeArchive="removeArchive"
      @viewLetter="viewLetter"
    />
    <router-view />
  </div>
</template>
<script>
import Axios from "axios";
import Letters from "../../../components/letters/Letters.vue";
export default {
  components: {
    Letters,
  },
  data() {
    return {
      search: "",
      letters: [],
      isLoaded: false,
      notSeen: "",
      role:""
    };
  },
  methods: {
    viewLetter(id) {
      this.$router.push('/' + this.role + "/secretariat/inbox/letterInfo/" + id);
    },
    getLetters() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letters/inbox",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.letters = res.data.letters;
            this.notSeen = res.data.notSeen;
            this.isLoaded = true;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    save(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/mark",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeSave(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeMark",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    archive(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/archive",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    removeArchive(id) {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/removeArchive",
        {
          letterId: id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.getLetters();
            }, 100);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },

  mounted() {
    this.role = localStorage.getItem("role");
    this.getLetters();
  },
};
</script>
